import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
 faClock,
 faEye,
 faGem,
 faUmbrella,
} from '@fortawesome/pro-light-svg-icons'
 
const ServiceOutsourcingOffer = () => (
 <section className="service-outsourcing py-5 bg-faded">
   <div className="container">
     <div className="row mb-5">
       <div className="col-sm-12 col-md-4">
         <h4 className="seo-h2 text-sm-center text-md-right mt-0">
           Time & materials
           <br />
           löpande räkning
         </h4>
       </div>
       <div className="col-sm-12 col-md-8">
         <div className="card-group mb-5">
           <div className="card">
             <div className="card-body">
               <FontAwesomeIcon
                 icon={faEye}
                 size="2x"
                 className="text-success mb-2"
               />
               <br />
               <strong>Transparens</strong>
               <br />
               <p className="text-sm text-muted text-italic">
                 Månatliga betalningar ger dig bättre ekonomisk kontroll över projektet. Inga dolda kostnader, inga överraskningar.
               </p>
             </div>
           </div>
           <div className="card">
             <div className="card-body">
               <FontAwesomeIcon
                 icon={faClock}
                 size="2x"
                 className="text-success mb-2"
               />
               <br />
               <strong>Tillgänglighet</strong>
               <br />
               <p className="text-sm text-muted text-italic">
                 En dedikerad grupp programmerare jobbar bara med ditt projekt
               </p>
             </div>
           </div>
           <div className="card">
             <div className="card-body">
               <FontAwesomeIcon
                 icon={faUmbrella}
                 size="2x"
                 className="text-success mb-2"
               />
               <br />
               <strong>Säkerhet</strong>
               <br />
               <p className="text-sm text-muted text-italic">
                 Våra koder är alltid säkra och vi följer gällande säkerhetsavtal och säkerhetspolicyer.
               </p>
             </div>
           </div>
           <div className="card">
             <div className="card-body">
               <FontAwesomeIcon
                 icon={faGem}
                 size="2x"
                 className="text-success mb-2"
               />
               <br />
               <strong>Kvalitet</strong>
               <br />
               <p className="text-sm text-muted text-italic">
                 Vårt utvecklarteam består av erfarna programmerare som fokuserar på affärsmål.
               </p>
             </div>
           </div>
         </div>
         <p>
           <strong>Time & Materials</strong> är en betalningsmodell som gagnar båda parter. Dess transparens bidrar till att kunden har bättre kontroll över sina kostnader och betalar endast för faktiskt utfört arbete. Denna budgeteringsmetod förbättrar arbetsflödet och möjliggör finansiering av ett projekt i fördelaktiga trancher.
         </p>
         <p>
           Fördelarna med <strong>Time & Materials</strong>:
         </p>
         <ul>
           <li>Kvalitetskontroll</li>
           <li>Låga risker</li>
           <li>Inga tillkommande kostnader</li>
           <li>En projektledares support för ett bättre arbetsflöde</li>
         </ul>
         <p>
           Den här lösningen passar utmärkt för startups som ofta saknar detaljerade produktspecifikationer,
           <strong className="seo-span"> app-prototyper</strong> eller exakta tidskrav.
         </p>
       </div>
     </div>
   </div>
 </section>
)
 
export default ServiceOutsourcingOffer