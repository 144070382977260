import React from 'react'
import { Category, scrollToForm } from '../../contact/ContactForm'

const ServiceOutsourcingOfferCta = () => (
  <section className="service-web">
    <div className="container">
      <div className="row py-5">
        <div className="col-xs-12 col-sm-4 col-lg-6">
          <div className="lead text-left text-sm-right">
            <div className="lead text-muted">
              <h1 className="seo-span text-success">
              Outsourca programutvecklingen till Polen
              </h1>
              <br />
              och ta ditt företag till nästa nivå
            </div>
            <a
              href="#contact-partial"
              data-form-category="outsourcing"
              className="btn btn-success my-2"
              id="software-outsourcing-more-btn"
              onClick={event => {
                event.preventDefault()
                scrollToForm(Category.FreeConsultation, 0, 500)
              }}
            >
              Få en gratis konsultation
            </a>
          </div>
        </div>
        <div className="col-xs-12 col-sm-4 col-lg-6">
          <div className="seo-p">
          Våra  <h2 className="seo-span">nearshore-programmeringsexperter </h2> är redo att börja utveckla din app 
          (och att komma överens om projektets krav och omfattning). 
            <h2 className="seo-span">Offshore outsourcing </h2>hjälper
           dig utveckla ditt projekt utan att behöva nyanställa och du betalar endast för det du faktiskt får.
            <h3 className="seo-span">Outsourca till Polen </h3> och spara upp till 30% av projektkostnader.
             Låt Zaven och våra specialister bli en del av ditt företag.
          </div>
          <p className="text-sm text-muted">
            Vårt
            <strong className="seo-regular">
            nearshore/offshore-team 
            </strong>
            har de duktigaste specialisterna: juniora och seniora programmerare, 
            QA-testare, projektledare, affärsanalytiker, SCRUM masters och dedikerad kundsupport.&nbsp;
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceOutsourcingOfferCta
