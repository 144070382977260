import React from 'react'
 
const ServiceOutsourcingDevelopmentModels = () => (
 <section className="service-outsourcing py-5">
   <div className="container">
     <div className="row">
       <div className="col-md-8 offset-md-2">
         <h4 className="seo-h2 text-sm-center">Utvecklingsmodeller</h4>
         <div className="lead my-2 text-sm-center">
           Det finns olika utvecklingsmodeller för webb- och mobilappar. Vi är öppna för dina behov och förslag. Det enda vi aldrig kompromissar med är våra höga standarder.
         </div>
       </div>
     </div>
 
     <div className="row my-5">
       <div className="col-sm-12 col-md-4">
         <h2 className="seo-h3 mt-2">
           <span className="text-muted display-4 float-left mr-3">NDC</span>
           Nearshore <br />
           <span className="text-success">
             development <br />
             center
           </span>
         </h2>
         <br className="clearfix" />
         <div className="seo-p">
           <strong>En utmärkt lösning för alla europeiska företag</strong>. Våra teammedlemmar kan engelska och tyska.Nearshore outsourcing och vårt västerländska tänkesätt gör att vi kan skapa långsiktiga affärsrelationer utan gränser.
         </div>
         <div className="text-sm text-muted">
           Vi kan träffa våra europeiska kunder regelbundet tack vare bra flygförbindelser från och till Polen. Du kan fokusera på din verksamhet och låta oss utveckla dina webb- och mobilappar till ett rimligt pris. Vårt team består av hängivna och talangfulla teknikexperter som arbetar enligt det agila förhållningssättet. Varmt välkommen till vårt kontor i Wroclaw – Polens IT-huvudstad.
         </div>
       </div>
       <div className="col-sm-12 col-md-4">
         <h2 className="seo-h3 mt-2">
           <span className="text-muted display-4 float-left mr-3">ODC</span>
           Offshore <br />
           <span className="text-success">
             development <br />
             center
           </span>
         </h2>
         <br className="clearfix" />
         <div className="seo-p">
           <strong>
             För våra kunder från USA, Kanada, Skandinavien, Asien och Australien
           </strong>
           &nbsp; som värdesätter kunniga experter, kommunikation och styrning på engelska, europeisk lagstiftning och säkerhetstänk samt högkvalitativ kod.
         </div>
 
         <div className="text-sm text-muted">
           ODC möjliggör samarbete med kunder från hela världen. Att satsa på offshoring i Polen är fördelaktigt för våra utländska kunder då: Polen är medlem i EU och Schengen, så valutakursen är mycket stabil vilket gör det enkelt att uppskatta ett projekts kostnader. Vi följer internationella standarder och är mycket snabba vilket gör oss till en perfekt långsiktig affärspartner.
         </div>
       </div>
       <div className="col-sm-12 col-md-4">
         <h2 className="seo-h3 mt-2">
           <span className="text-muted display-4 float-left mr-3">DDC</span>
           Dedicated <br />
           <span className="text-success">
             development <br />
             center
           </span>
         </h2>
         <br className="clearfix" />
         <div className="seo-p">
           <strong>Idealisk för större IT-byråer</strong>, som behöver outsourca en viss del av arbetet för att slippa nyanställa.
         </div>
         <div className="text-sm text-muted">
           Denna modell ger dig möjligheten att anlita ett helt <strong>dedikerat expertteam</strong> som jobbar heltid med ditt projekt. Våra specialister kan marknaden och, eftersom de jobbar bara med ditt projekt, kommer att kunna din produkt och veta exakt vad de ska göra för att utveckla den. Det räcker att en av dina medarbetare skickar anvisningar och tar emot våra rapporter. Är du intresserad? Skicka en kort beskrivning av ditt projekt och vi tar fram en individuell offert på vad det skulle kosta att anlita ett dedikerat utvecklarteam.
         </div>
       </div>
     </div>
   </div>
 </section>
)
 
export default ServiceOutsourcingDevelopmentModels
 
