import React from 'react'

const ServiceOutsourcingZavenDreamTeam = () => (
  <section className="partial-cta-mvp bg-dark">
    <div className="container">
      <p className="lead text-center">
        <strong>Anlita ditt drömteam från Zaven: </strong>{' '}
        <span className="text-success">snabbt, effektivt och målinriktat.</span>
      </p>
    </div>
  </section>
)

export default ServiceOutsourcingZavenDreamTeam
